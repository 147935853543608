
    import { defineComponent } from 'vue';
    import MediaPostContent from '@/components/MediaPostContent.vue';
    import Loader from '@/components/Loader.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';
    import { MediaPost } from '@/interfaces/MediaPost';

    export default defineComponent({
        name: 'Memories',
        components: {
            MediaPostContent, Loader, Breadcrumbs,
        },

        data() {
            return {
                years: [],
                prettyDate: '',
                loaded: false,
                breadcrumbs: [
                    {
                        name: 'Memories',
                        url: 'memories',
                    },
                ],
            }
        },

        async created() {
            document.title = 'Memories | virtualwolf.xyz';

            await this.fetchData();
        },

        methods: {
            async fetchData() {
                this.loaded = false;

                const response = await fetch(`https://virtualwolf.org/rest/memories`);
                const data = await response.json();

                if (data.years.length > 0) {
                    this.years = data.years;
                } else {
                    this.years = [];
                }

                this.prettyDate = data.prettyDate;

                this.breadcrumbs.push({
                    name: this.prettyDate,
                    url: '',
                });

                document.title = `Memories - ${this.prettyDate} | virtualwolf.xyz`

                this.loaded = true;
            }
        }
    });
